<template>
  <div class="home">
    <div class="home_item">
      <h2 class="home_item_title">Sangfor Library</h2>
      <div class="home_item_search">
        <el-form
          ref="form"
          :model="homeForm"
          class="home_item_search_form"
          @submit.native.prevent
        >
          <el-form-item>
            <el-checkbox-group v-model="homeForm.type">
              <el-checkbox
                v-for="val in homeForm.optinos"
                :key="val.menuId"
                :label="val.menuId"
                >{{ val.label }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item style="margin-top: 5px">
            <div class="inputborder">
              <el-input
                placeholder="Search documents or hardware"
                v-model="homeForm.keyWord"
                @keyup.enter.native.prevent="handleSearch"
              >
                <template slot="append"
                  ><div class="searchIcon" @click="handleSearch">
                    <svg-icon
                      icon-class="search"
                    /></div
                ></template>
              </el-input>
            </div>
          </el-form-item>
          <!-- <div class="fast_track">
            <div class="fast_track_item" v-for="(val,index) in fastTrackData" :key="index">
              <svg-icon :icon-class="val.icon"></svg-icon>
              <p class="fast_track_item_name">{{val.name}}</p>
            </div>
          </div> -->
        </el-form>
      </div>
    </div>
    
  </div>
</template>

<script>
// import {getMenuInfo} from '@/api/login.js'
export default {
  name: "home",
  data() {
    return {
      homeForm: {
        type: [],
        keyWord: "",
      },
      optinos: [],
      fastTrackData: [
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
        {
          name: "documents",
          icon: "fasttrack",
        },
      ],
    };
  },
  methods: {
    //搜索
    handleSearch() {
      this.$router.push({ path: "/searchDetail", query: this.homeForm });
    }
  },
  created() {
    this.homeForm.optinos = this.$store.state.menuData.slice(1);
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 920px;
  .home_item {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/HomeBackground.png") no-repeat;
    background-size: cover;
    background-position: center 0;
    .home_item_title {
      width: 100%;
      padding: 100px 0 80px 0;
      font-size: 60px;
      color: #fff;
      text-align: center;
    }
    .home_item_search {
      width: 100%;
      padding: 0 400px;
      .home_item_search_form {
        .inputborder {
          // border: 3px solid #fff;
          background: #fff;
          border-radius: 4px;
          .searchIcon {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 58px;
            cursor: pointer;
            .svg-icon {
              font-size: 24px;
            }
          }
          input {
            font-size: 24px;
          }
        }
        .fast_track {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .fast_track_item {
            width: 14.5%;
            text-align: center;
            padding-top: 40px;
            cursor: pointer;
            .svg-icon {
              font-size: 58px;
            }
            .fast_track_item_name {
              width: 100%;
              font-size: 18px;
              color: #fff;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>